import React, { useState } from 'react';
import FirstScreen from './components/FirstScreen';
import GPTSelection from './components/GPTSelection';
import UserInputScreen from './components/UserInputScreen';  // Only for Companion GPT
import LanguageSelection from './components/LanguageSelection';
import ConversationScreen from './components/ConversationScreen';  // For Companion GPT
import InsightConversationScreen from './components/InsightConversationScreen';  // For Insight GPT
import './components/HomePage.css';

const App = () => {
  const [hasStarted, setHasStarted] = useState(false);  // Track if app has started (FirstScreen complete)
  const [selectedGPT, setSelectedGPT] = useState(null);  // Track GPT selection
  const [showUserInput, setShowUserInput] = useState(false);  // Show UserInputScreen only for Companion GPT
  const [showLanguageSelection, setShowLanguageSelection] = useState(false);  // Track if LanguageSelection should show
  const [selectedLanguage, setSelectedLanguage] = useState(null);  // Track the selected language

  // Start the app from FirstScreen
  const handleStart = () => {
    console.log('Starting the app...');
    setHasStarted(true);  // Proceed to GPTSelection screen
  };

  // Handle GPT selection
  const handleNextFromGPT = (gpt) => {
    const gptSelection = gpt.toLowerCase();
    setSelectedGPT(gptSelection);  // Store selected GPT type
    if (gptSelection === 'companion') {
      setShowUserInput(true);  // Show UserInputScreen only if "Companion GPT" is selected
    } else {
      setShowLanguageSelection(true);  // Directly move to LanguageSelection for Insight GPT
    }
    console.log('Selected GPT:', gptSelection);
  };

  // Handle completion of UserInputScreen (Companion GPT)
  const handleNextFromUserInput = () => {
    setShowUserInput(false);  // Hide UserInputScreen
    setShowLanguageSelection(true);  // Move to LanguageSelection after UserInputScreen
    console.log('Proceeding from UserInputScreen...');
  };

  // Handle language selection
  const handleNextFromLanguage = (language) => {
    setSelectedLanguage(language);  // Store selected language
    console.log('Selected Language:', language);
  };

  return (
    <div
      style={{
        backgroundImage: `url('/assets/background-pattern.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
      }}
    >
      {/* Screen rendering based on app state */}
      {!hasStarted ? (
        <>
          <FirstScreen onStart={handleStart} />
          {console.log('Rendering FirstScreen')}
        </>
      ) : !selectedGPT ? (
        <>
          <GPTSelection onNext={handleNextFromGPT} />
          {console.log('Rendering GPTSelection')}
        </>
      ) : showUserInput ? (
        <>
          <UserInputScreen onNext={handleNextFromUserInput} />
          {console.log('Rendering UserInputScreen (Companion GPT)')}
        </>
      ) : showLanguageSelection && !selectedLanguage ? (
        <>
          <LanguageSelection
            selectedGPT={selectedGPT}
            onNext={handleNextFromLanguage}  // Store selected language and proceed
          />
          {console.log('Rendering LanguageSelection')}
        </>
      ) : selectedGPT === 'companion' ? (
        <>
          <ConversationScreen language={selectedLanguage} />  {/* Companion GPT */}
          {console.log('Navigated to ConversationScreen (Companion GPT)')}
        </>
      ) : (
        <>
          <InsightConversationScreen language={selectedLanguage} />  {/* Insight GPT */}
          {console.log('Navigated to InsightConversationScreen (Insight GPT)')}
        </>
      )}
    </div>
  );
};

export default App;