import React, { useState } from 'react';
import './UserInputScreen.css';
import worldMap from '../assets/world_map_white.png'; // Import the world map image
import { salesmenDictionary } from '../utils/salesMenDictionary'; // Import the salesmen data

const UserInputScreen = ({ onNext }) => {
  const [query, setQuery] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(''); // Tracks the selected value for enabling the "Next" button

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setSelectedValue(''); // Clear the selected value when typing

    // Filter suggestions based on the input value
    const suggestions = Object.values(salesmenDictionary).filter(
      (name) =>
        name.toLowerCase().includes(value.toLowerCase()) // case-insensitive search
    );

    setFilteredSuggestions(suggestions);
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion); // Set the clicked suggestion to the input field
    setFilteredSuggestions([]); // Hide the dropdown
    setSelectedValue(suggestion); // Set the selected value to enable the "Next" button
  };

  return (
    <div className="ui_first-screen-container">
      {/* Header with the Sales Sherpa logo */}
      <div className="ui_header">
        <img src={require('../assets/logo/sales-sherpa-logo2.png')} alt="Sales Sherpa Logo" className="ui_right-logo" />
      </div>

      {/* Main content */}
      <div className="ui_main-content">
        <div className="ui_left-side">
          {/* Inline world map image */}
          <img src={worldMap} alt="Map Preview" className="ui_map-image-inline" />

          {/* Background image for additional styling */}
          <div className="ui_map-image" style={{ backgroundImage: `url(${worldMap})` }}></div>
        </div>
        <div className="ui_right-side">
          <div className="ui_content">
            <h1>What's Your Username?</h1>

            <input
              type="text"
              className="ui_input"
              value={query}
              onChange={handleInputChange}
              placeholder="Type in your username"
            />

            {/* Dropdown for filtered suggestions */}
            {query && filteredSuggestions.length > 0 && (
              <ul className="ui_suggestions">
                {filteredSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="ui_suggestion-item"
                    onClick={() => handleSuggestionClick(suggestion)} // Handle suggestion click
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}

            {/* Next button should be beneath the text box and right-aligned */}
            <div className="ui_button-wrapper">
              <button
                className="ui_next-button"
                onClick={onNext}
                disabled={!selectedValue} // Disable the button if no value is selected
              >
                Next <span>&#8594;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInputScreen;