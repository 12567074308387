import React, { useState } from 'react';
import './LanguageSelection.css';
import worldMap from '../assets/world_map_white.png'; // Import the map image

const LanguageSelection = ({ onNext }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  // List of available languages
  const languages = ['English', 'Hindi', 'Hinglish'];

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
  };

  const isNextDisabled = !selectedLanguage;

  return (
    <div className="fs_first-screen-container">
      {/* Header with the Sales Sherpa logo */}
      <div className="fs_header">
        <img src={require('../assets/logo/sales-sherpa-logo2.png')} alt="Sales Sherpa Logo" className="fs_right-logo" />
      </div>

      {/* Main content */}
      <div className="fs_main-content">
        <div className="fs_left-side">
          {/* Inline world map image */}
          <img src={worldMap} alt="Map Preview" className="fs_map-image-inline" />

          {/* Background image for additional styling */}
          <div className="fs_map-image" style={{ backgroundImage: `url(${worldMap})` }}></div>
        </div>

        <div className="fs_right-side">
          <div className="fs_content">
            <h1 className="language-title">Please Select Your Language</h1>

            <div className="gpt-options">
              {languages.map((language) => (
                <button
                  key={language}
                  className={`gpt-option ${selectedLanguage === language ? 'selected' : ''}`}
                  onClick={() => handleLanguageSelect(language)}
                >
                  {language}
                </button>
              ))}
            </div>

            {/* Next button styled similarly to GPT selection screen */}
            <div className="fs_button-wrapper">
              <button
                className="fs_next-text-button"
                onClick={() => onNext(selectedLanguage)}
                disabled={isNextDisabled} // Disable until a language is selected
              >
                Next <span>&#8594;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelection;