export const salesmenDictionary = {
    "CEAA02": "Agah Hernandez Cruz",
    "CEBB03": "Aristotle Dela Paz Buñag",
    "CEBC04": "Victor Nalla Ipanag Jr.",
    "CEBC05": "Enrique De Mesa Ramos",
    "CEBC06": "Donardo Vasallo Sampillo",
    "CEEB01": "Justine Bryan Castro Comia",
    "CEEB02": "Jacinto Carpio De Jesus",
    "CEEC03": "Robert Arquillano Ylade",
    "CEFB02": "Roel Cantos Fajardo",
    "CEFB07": "Ryan Castulo Jaim",
    "CEFB09": "Melvin John Carlos Villafuerte",
    "CEFB10": "Cedric Allen Quirao Camua",
    "CEFB13": "Jerico Tamayo Reyes",
    "CEFC03": "Ehrico Ravago Lubao",
    "CEFC05": "Billie Joshua Fuertes Velasco",
    "CEFC06": "Ryan Latosa Ignacio",
    "CEFC11": "Karl Marx Bigornia Berce",
    "Jreyes": "Jeron Octavio Reyes",
    "SKBA03": "Joshua Albufera Velasco",
    "SKEA01": "John Gemer Oferina Gacer",
    "SKEA02": "Michael Fialde Aguilar",
    "SKFA01": "Leopoldo Jr. Mila Acero",
    "SKFA02": "Joezel K Reyes",
    "SKFA03": "Rojene Piliin Velasco",
    "SKFA04": "Kiefer Ray S. Pascual",
    "SKFA05": "Jimuel Bryan R. Alvarez",
    "SKFA06": "Marivic Perez Briones",
    "SKFA07": "John Patrick Bobis Balanta",
    "SKFA08": "Rafael Rico Ticong",
    "SKFA09": "Raymund V Teña",
    "SKFA10": "Antonio Quiamno",
    "SKFA11": "Roderick Tolentino Paguio",
    "SLBA01": "Adam Panganiban",
    "SLBA03": "Weniton Jr. Abarquez",
    "SLBA04": "Eduardo Nogueras",
    "SLBA05": "Bryan Garcia",
    "SLBA06": "Ronaldo Ordoñez",
    "SLBA07": "Ryan Advincula",
    "SLEB01": "Arnesto Bautista",
    "SLEC01": "Joel Fabellon",
    "SLFB01": "Allan Vasquez",
    "SLFB02": "Jay V. Binuya",
    "SLFB03": "Hammish Fabreag",
    "SLFB04": "John Arvin Estrada",
    "SLFB05": "Kenneth Castino",
    "SLFB06": "Arjhay Bagasina",
    "SLFB07": "Marvin Mata",
    "SLFC02": "William Abejo",
    "SLFC03": "John Kevin Sabangan",
    "SLFC05": "Lindo Pelino",
    "SLFC07": "Rolando Casilag"
  };