// speechToText.js

export const initSpeechRecognition = (language, handleSendMessage, setIsRecording) => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert('Speech recognition is not supported in your browser.');
      return null;
    }
  
    const recognition = new SpeechRecognition();
    recognition.lang = language === 'English' ? 'en-US' : language === 'Hindi' ? 'hi-IN' : 'en-IN'; // Handle Hinglish
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;
  
    let mediaRecorder = null;
    let audioChunks = [];
    let recordedAudio = null;
  
    const startRecording = () => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        mediaRecorder = new MediaRecorder(stream);
  
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.push(event.data);
          }
        };
  
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
          recordedAudio = audioBlob;
          audioChunks = [];
        };
  
        mediaRecorder.start();
      });
    };
  
    recognition.onstart = () => {
      console.log('Speech recognition started...');
      setIsRecording(true);
      startRecording();
    };
  
    recognition.onresult = (event) => {
      const transcription = event.results[0][0].transcript;
      console.log('Transcribed text:', transcription);
  
      const audioUrl = recordedAudio ? URL.createObjectURL(recordedAudio) : null;
      setIsRecording(false);
      handleSendMessage(transcription, audioUrl);
  
      recognition.stop();
    };
  
    recognition.onerror = (event) => {
      console.error('Error during speech recognition:', event.error);
      setIsRecording(false);
      recognition.stop(); // Ensure speech recognition is stopped on error
    };
  
    recognition.onend = () => {
      console.log('Speech recognition ended.');
      setIsRecording(false);
  
      if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop(); // Stop recording
      }
    };
  
    return recognition;
  };
  
  export const stopSpeechRecognition = (recognition, mediaRecorder) => {
    if (recognition) recognition.stop();
    if (mediaRecorder && mediaRecorder.state !== 'inactive') mediaRecorder.stop();
  };