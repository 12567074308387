import React, { useState, useEffect, useRef } from 'react';
import './HomePage.css'; 
import logoLeft from '../assets/logo/logo-left.png'; // Left Sales Sherpa Logo
import logoRight from '../assets/logo/logo-right.png'; // Right JKC Logo
import InputField from './InputField';
import ConversationBubbles from './ConversationBubbles';
import { fetchWelcomeMessage, formatBotResponse, responseApiCall } from '../utils/utils';  // Import responseApiCall
import { initSpeechRecognition, stopSpeechRecognition } from '../utils/speechToText';
import { generateCustomThreadId } from '../utils/threadUtils';  // Import the UUID generator

const ConversationScreen = ({ language }) => {
  const [textContent, setTextContent] = useState({});
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [typing, setTyping] = useState(false);
  const [buttonList, setButtonList] = useState([]);
  const [showInitialScreen, setShowInitialScreen] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [recentChats, setRecentChats] = useState([]); // Add recent chats state
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Add sidebar toggle state
  
  // Store thread_id in state (will persist until page refresh or navigation)
  const [threadId, setThreadId] = useState('');

  const recognitionRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  // Generate the thread_id when the component first mounts
  useEffect(() => {
    const newThreadId = generateCustomThreadId();  // Use the utility function to generate the UUID
    setThreadId(newThreadId);  // Set thread_id in state
    console.log(`Generated thread_id: ${newThreadId}`);
  }, []);  // Runs only once when the component is mounted

  // useEffect(() => {
  //   const fetchMessage = async () => {
  //     const data = await fetchWelcomeMessage(language);
  //     setTextContent((prev) => ({
  //       ...prev,
  //       instruction: data.welcome_message,
  //     }));
  //     setButtonList([
  //       data.button_texts.button1,
  //       data.button_texts.button2,
  //       data.button_texts.button3,
  //       data.button_texts.button4,
  //       data.button_texts.button5,
  //     ]);
  //   };

  //   fetchMessage();
  // }, [language]);

  const handleSendMessage = async (text, audioUrl = null) => {
    const messageToSend = text || input;
    if (!messageToSend.trim()) {
      console.log('Message is empty. Cannot send.');
      return;
    }

    setMessages((prevMessages) => [...prevMessages, { text: messageToSend, type: 'user', audioUrl }]);
    setInput('');
    setShowInitialScreen(false);
    setTyping(true);

    // Prepare the payload for the API call
    const payload = {
      message: messageToSend,
      language,
      sales_rep: 'OFF_SM_1',
      thread_id: threadId,  // Use the persistent thread_id
    };

    try {
      // Call the API using responseApiCall from utils.js
      const data = await responseApiCall(payload);
      setTyping(false);

      // Ensure that shopImages are passed correctly here
      const formattedMessages = formatBotResponse(data.response, []);
      setMessages((prevMessages) => [...prevMessages, ...formattedMessages]);
    } catch (error) {
      setTyping(false);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: `Error: Could not send the message. ${error.message}`, type: 'error' },
      ]);
    }
  };

  // Toggle the sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSpeechStart = () => {
    recognitionRef.current = initSpeechRecognition(language, handleSendMessage, setIsRecording);
    if (recognitionRef.current) {
      recognitionRef.current.start();
    }
  };

  const handleSpeechEnd = () => {
    stopSpeechRecognition(recognitionRef.current, mediaRecorderRef.current);
  };

  return (
    <div className="conversation-layout">
      {/* Sidebar for chat history */}
      <div className={`sidebar ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <h3>Recent Chats</h3>
        <ul>
          {recentChats.map((chat, index) => (
            <li key={index}>
              {`Chat ${index + 1}`} {/* Displaying chat number or other identifier */}
            </li>
          ))}
        </ul>
        <button className="new-chat-button" onClick={() => setMessages([])}>
          New Chat
        </button>
        <div className="sidebar-bottom-text">
          Data available from Dec 2022 to Aug 2024 [except March 2024]*
        </div>
      </div>

      {/* Hamburger menu for mobile view */}
      <div className="hamburger-menu" onClick={toggleSidebar}>
        &#9776;
      </div>

      {/* Main conversation area */}
      <div className="main-conversation">
        <header className="top-header">
          <img src={logoLeft} alt="Left Logo" className="logo-left" />
          <h1 className="top-header-title">Sales Sherpa V2</h1>
          <img src={logoRight} alt="Right Logo" className="logo-right" style={{ backgroundColor: 'red', border: '2px solid blue' }} /> {/* Right JKC Logo with debugging styles */}
        </header>

        {showInitialScreen && (
          <>
            <div className="avatar-container">
              {/* Avatar or Intro section */}
            </div>
            <p className="instruction">{textContent.instruction || 'How can I help you today?'}</p>

            {buttonList.length > 0 && (
              <div className="conversation-options">
                {buttonList.map((buttonText, index) => (
                  <button
                    key={index}
                    className="conversation-button"
                    onClick={() => handleSendMessage(buttonText)}
                  >
                    {buttonText}
                  </button>
                ))}
              </div>
            )}
          </>
        )}

        {/* Conversation bubbles */}
        <ConversationBubbles messages={messages} typing={typing} />

        {!showInitialScreen && buttonList.length > 0 && (
          <div className="conversation-options">
            {buttonList.map((buttonText, index) => (
              <button
                key={index}
                className="conversation-button"
                onClick={() => handleSendMessage(buttonText)}
              >
                {buttonText}
              </button>
            ))}
          </div>
        )}

        {/* Input field at the bottom */}
        <InputField
          input={input}
          setInput={setInput}
          handleSendMessage={handleSendMessage}
          isRecording={isRecording}
          handleSpeechStart={handleSpeechStart}
          handleSpeechEnd={handleSpeechEnd}
        />
      </div>
    </div>
  );
};

export default ConversationScreen;