// utils.js

import { has } from "lodash";

export const apiUrl = process.env.REACT_APP_API_URL;  // Welcome API URL from env
export const responseApiUrl = process.env.REACT_APP_RESPONSE_API_URL;  // Response API URL from env
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// Fetch welcome message
export const fetchWelcomeMessage = async (language) => {
  const response = await fetch(`${apiUrl}${language}`);
  return await response.json();
};

// Make API call to /response
export const responseApiCall = async (payload) => {
  try {
    const response = await fetch(responseApiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),  // Send request body as JSON
    });
    
    const data = await response.json();  // Parse the JSON response
    return data;  // Return the parsed data
  } catch (error) {
    console.error('Error during API call:', error);
    throw error;  // Re-throw the error for error handling in the calling component
  }
};

// Generate random latitude and longitude for maps
export const getRandomCoordinates = () => {
  const minLatitude = 21.1700000;
  const maxLatitude = 21.1800000;
  const minLongitude = 72.8300000;
  const maxLongitude = 72.8400000;

  const latitude = minLatitude + Math.random() * (maxLatitude - minLatitude);
  const longitude = minLongitude + Math.random() * (maxLongitude - minLongitude);

  return { latitude, longitude };
};

// Generate Google Maps link
export const generateGoogleMapsLink = (latitude, longitude) => {
  return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
};

// Generate static map image URL
export const generateMapImageUrl = (latitude, longitude) => {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=14&size=200x200&markers=color:red%7C${latitude},${longitude}&key=${googleMapsApiKey}`;
};

// Format bot response, extract latitude and longitude, and remove "Coordinates"
export const formatBotResponse = (response, shopImages) => {
  const parts = response.split(/\n\d+\./);  // Split response by numbered list items
  const intro = parts[0];  // First part of response (intro)

  const stores = parts.slice(1).map((store, index) => {
    // Use regex to extract coordinates (Latitude and Longitude)
    const coordinateMatch = store.match(/Latitude\s+([0-9.-]+),\s+Longitude\s+([0-9.-]+)/i);
    
    let latitude = null;
    let longitude = null;

    if (coordinateMatch) {
      latitude = parseFloat(coordinateMatch[1]);
      longitude = parseFloat(coordinateMatch[2]);
      console.log(`Extracted coordinates for store ${index + 1}:`, { latitude, longitude });
    } else {
      console.log(`No coordinates found for store ${index + 1}`);
    }

    // Clean store text by removing coordinates
    const cleanedStore = store.replace(/- \*\*Coordinates:\*\*.*$/s, '').trim();
    const formattedText = cleanedStore.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');  // Format bold text
    const hasAddress = store.toLowerCase().includes('address') || store.includes('पता') || (latitude && longitude);  // Check if address or coordinates exist

    // Generate map URL and link
    const mapUrl = hasAddress && latitude && longitude ? generateMapImageUrl(latitude, longitude) : null;
    const googleMapsLink = hasAddress && latitude && longitude ? generateGoogleMapsLink(latitude, longitude) : null;
    
    // Log generated map URL
    if (mapUrl) {
      console.log(`Generated map URL for store ${index + 1}: ${mapUrl}`);
    } else {
      console.log(`No map URL generated for store ${index + 1}`);
    }

    return {
      text: `${index + 1}. ${formattedText}`,
      type: 'bot',
      image: hasAddress ? shopImages[Math.floor(Math.random() * shopImages.length)] : null,  // Show image if address exists
      mapUrl,
      googleMapsLink,
    };
  });

  return [{ text: intro, type: 'bot' }, ...stores];  // Return formatted response with intro and stores
};