import React from 'react';
import './FirstScreen.css';
import worldMap from '../assets/world_map_white.png'; // Importing the map image

const FirstScreen = ({ onStart }) => {
  return (
    <div className="fs_first-screen-container">
      {/* Header with the BCGX logo */}
      <div className="fs_header">
        <img src={require('../assets/logo/sales-sherpa-logo2.png')} alt="BCGX Logo" className="fs_right-logo" />
      </div>

      {/* Main content */}
      <div className="fs_main-content">
        <div className="fs_left-side">
          {/* Display the world map image */}
          <img src={worldMap} alt="Map Preview" className="fs_map-image-inline" />

          {/* Background image for additional styling */}
          <div className="fs_map-image" style={{ backgroundImage: `url(${worldMap})` }}></div>
        </div>
        <div className="fs_right-side">
          <div className="fs_content">
            <h1>Hey There!</h1>
            <p className="fs_tagline">Welcome to Sales Sherpa</p>
            <button className="fs_primary-button" onClick={onStart}>
              Let's Go <span>&#8594;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstScreen;