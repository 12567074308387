import React, { useState } from 'react';
import './GPTSelection.css';
import worldMap from '../assets/world_map_white.png'; // Import the map image

const GPTSelection = ({ onNext }) => {
  const [selectedGPT, setSelectedGPT] = useState(null);

  // Handler for selecting GPT option
  const handleGPTSelect = (gpt) => {
    setSelectedGPT(gpt);  // Save the selected GPT (companion or insight)
  };

  // Check if no GPT is selected to disable the Next button
  const isNextDisabled = !selectedGPT;

  return (
    <div className="fs_first-screen-container">
      {/* Header with the Sales Sherpa logo */}
      <div className="fs_header">
        <img src={require('../assets/logo/sales-sherpa-logo2.png')} alt="Sales Sherpa Logo" className="fs_right-logo" />
      </div>

      {/* Main content */}
      <div className="fs_main-content">
        <div className="fs_left-side">
          {/* Inline world map image */}
          <img src={worldMap} alt="Map Preview" className="fs_map-image-inline" />

          {/* Background image for additional styling */}
          <div className="fs_map-image" style={{ backgroundImage: `url(${worldMap})` }}></div>
        </div>

        <div className="fs_right-side">
          <div className="fs_content">
            <h1 className="gpt-title">Please Select Your GPT</h1>

            <div className="gpt-options">
              <button
                className={`gpt-option ${selectedGPT === 'insight' ? 'selected' : ''}`}
                onClick={() => handleGPTSelect('insight')}
              >
                Insight GPT
              </button>

              <button
                className={`gpt-option ${selectedGPT === 'companion' ? 'selected' : ''}`}
                onClick={() => handleGPTSelect('companion')}
              >
                Companion GPT
              </button>
            </div>

            {/* Next button should be centered beneath the GPT options */}
            <div className="fs_button-wrapper">
              <button
                className="fs_next-button"
                onClick={() => onNext(selectedGPT)}
                disabled={isNextDisabled} // Disable until a GPT is selected
              >
                Next <span>&#8594;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GPTSelection;