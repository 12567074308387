import React, { useState, useEffect, useRef } from 'react';
import './HomePage.css'; 
import logoLeft from '../assets/logo/sales-sherpa-logo2.png'; 
import logoRight from '../assets/logo/jkc-logo.png'; 
import InputField from './InputField';
import ConversationBubbles from './InsightConversationBubbles';
import { initSpeechRecognition, stopSpeechRecognition } from '../utils/speechToText';
import { generateCustomThreadId } from '../utils/threadUtils';
import arrowDownIcon from '../assets/icons/arrow-down-white.svg';
import arrowUpIcon from '../assets/icons/arrow-up-white.svg';

const INSIGHT_GPT_API_URL = 'https://apidev.aw-aicoach.pre-prd.gamma-platform.com/updated_messages/';
const useApiData = true;

const InsightConversationScreen = ({ language }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [typing, setTyping] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});

  const recognitionRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [threadId, setThreadId] = useState('');

  // Define data structure with items in array format
  const dataStructure = {
    Cluster: ["bihar","central", "north", "south"],
    Zone: ["bihar", "gj", "mp", "north", "rjn", "rjs", "south", "upe", "upw"],
    Region: [
      "andhra pradesh", "bihar", "chandigarh", "dadra and nagar hav.", "daman and diu", 
    "delhi", "goa", "gujarat", "haryana", "himachal pradesh", "jammu and kashmir", 
    "jharkhand", "karnataka", "kerala", "ladakh", "madhya pradesh", "maharashtra", 
    "punjab", "rajasthan", "uttar pradesh", "uttaranchal"
  ],
    State: [
      "bihar", "dl", "gj", "hr gurgaon", "hr hisar", "hr karnal", "kolhapur", "mp bhopal", 
    "mp indore", "mp jabalpur", "north karnataka", "pb", "pune", "rj ajmer", "rj jaipur", 
    "rj jodhpur", "rj sriganganagar", "rj udaipur", "south karnataka", "up agra", 
    "up bareilly", "up ghaziabad", "up gorakhpur", "up kanpur", "up lucknow", "up prayagraj", "utk"
  ],
    AO: ['agra', 'ahmedabad', 'ahmednagar', 'ajmer', 'aligarh', 'alwar', 'ambala', 'amritsar', 
      'arrah', 'banda', 'bangalore', 'bareilly', 'barmer', 'baroda', 'basti', 'bathinda', 
      'begusarai', 'belgaum', 'bharatpur', 'bhilwara', 'bhopal', 'bijapur', 'bikaner', 
      'chandigarh', 'chhindwara', 'darbhanga', 'davangere', 'dehradun', 'deoria', 'dhar', 
      'dungarpur', 'etah', 'faizabad', 'faridabad', 'gaya', 'ghaziabad', 'ghazipur', 'goa', 
      'godhra', 'gorakhpur', 'guna', 'gurgaon', 'gwalior', 'haridwar', 'hisar', 'hubli', 
      'indore', 'jabalpur', 'jaipur rural', 'jaipur urban', 'jaunpur', 'jhalawar', 'jhansi', 
      'jodhpur', 'kanpur', 'karnal', 'kerala', 'khargone', 'kolhapur', 'kota', 'lucknow', 
      'ludhiana', 'mainpuri', 'mangalore', 'meerut', 'mehsana', 'mirzapur', 'moga', 
      'moradabad', 'motihari', 'muzaffarpur', 'nadiad', 'nainital', 'nalanda', 'north central delhi', 
      'pali', 'panipat', 'patna', 'prayagraj', 'pune', 'raebareli', 'raichur', 'rajgarh', 
      'ratlam', 'ratnagiri', 'rewa', 'rohtak', 'sagar', 'saharanpur', 'sangli', 'sasaram', 
      'satna', 'sawai madhopur', 'shahdol', 'sikar', 'sirsa', 'sitapur', 'siwan', 'solapur', 
      'south east delhi', 'sri ganganagar', 'surat', 'udaipur', 'ujjain', 'varanasi', 'west delhi'],
    County: ['agar', 'agra', 'ahmedabad', 'ahmednagar', 'ajmer', 'aligarh', 'alirajpur', 'allahabad', 'almora', 
      'alwar', 'ambala', 'ambedkar nagar', 'amethi', 'amritsar', 'amroha (jyotiba phule)', 'anand', 
      'anantapur', 'anuppur', 'araria', 'arravali', 'arwal', 'ashoknagar', 'auraiya', 'aurangabad', 
      'aurangabad ( bh)', 'azamgarh', 'bagalkot', 'bageshwar', 'baghpat', 'bahraich', 'balaghat', 
      'ballari', 'ballia', 'balrampur', 'banaskantha', 'banda', 'banswara', 'barabanki', 'baran', 
      'bareilly', 'barmer', 'barnala', 'barwani', 'basti', 'bathinda', 'beed', 'begusarai', 'belagavi', 
      'bengaluru rural', 'bengaluru urban', 'betul', 'bhagalpur', 'bharatpur', 'bharuch', 'bhavnagar', 
      'bhilwara', 'bhind', 'bhiwani', 'bhojpur', 'bhopal', 'bidar', 'bijnor', 'bikaner', 'botad', 
      'budaun', 'bulandshahr', 'bundi', 'burhanpur', 'buxar', 'chamarajnagar', 'chamba', 'chamoli', 
      'champawat', 'chandauli', 'chandigarh', 'charkhi dadri', 'chhatarpur', 'chhindwara', 
      'chikballapur', 'chikkamagaluru', 'chitradurga', 'chitrakoot', 'chittorgarh', 'choota udaipur', 
      'churu', 'dadra and nagar haveli', 'dahod', 'dakshina kannada', 'daman', 'damoh', 'dang', 
      'darbhanga', 'datia', 'dausa', 'davanagere', 'dehradun', 'deoghar', 'deoria', 'devbhoomi dwarka', 
      'dewas', 'dhar', 'dharwad', 'dholpur', 'dindori', 'dumka', 'dungarpur', 'east champaran', 
      'east delhi', 'etah', 'etawah', 'faizabad', 'faridabad', 'faridkot', 'farrukhabad', 'fatehabad', 
      'fatehgarh sahib', 'fatehpur', 'fazilka', 'ferozepur', 'firozabad', 'gadag', 'ganderbal', 
      'gandhinagar', 'gautam budh nagar', 'gaya', 'ghaziabad', 'ghazipur', 'gonda', 'gopalganj', 
      'gorakhpur', 'guna', 'gurdaspur', 'gurgaon', 'gwalior', 'hamirpur', 'hamirpur (hp)', 'hanumangarh', 
      'hapur', 'harda', 'hardoi', 'haridwar', 'hassan', 'hathras', 'haveri', 'hazaribag', 'hisar', 
      'hoshangabad', 'hoshiarpur', 'indore', 'jabalpur', 'jaipur', 'jaisalmer', 'jalandhar', 'jalaun', 
      'jalna', 'jalore', 'jammu', 'jamnagar', 'jaunpur', 'jehanabad', 'jhabua', 'jhajjar', 'jhalawar', 
      'jhansi', 'jhunjhunu', 'jind', 'jodhpur', 'kaimur', 'kaithal', 'kalaburgi', 'kannauj', 'kannur', 
      'kanpur dehat', 'kanpur nagar', 'kapurthala', 'karauli', 'karnal', 'kasaragod', 'kasganj', 
      'kathua', 'katni', 'kaushambi', 'khagaria', 'khandwa', 'khargone', 'kheda', 'kodagu', 'kolar', 
      'kolhapur', 'koppal', 'kota', 'kozhikode', 'kurukshetra', 'kushinagar', 'kutch', 'lakhimpur kheri', 
      'lakhisarai', 'lalitpur', 'latur', 'leh', 'lucknow', 'ludhiana', 'madhubani', 'maharajganj', 
      'mahendragarh', 'mahisagar', 'mahoba', 'mainpuri', 'malappuram', 'mandla', 'mandsaur', 'mandya', 
      'mansa', 'mathura', 'mau', 'meerut', 'mehsana', 'mewat', 'mirjapur', 'mirzapur', 'moga', 
      'moradabad', 'morbi', 'morena', 'muktsar', 'mumbai', 'munger', 'muzaffarnagar', 'muzaffarpur', 
      'mysuru', 'nagaur', 'nainital', 'nalanda', 'narmada', 'narsinghpur', 'navsari', 'nawada', 
      'nawanshahr', 'neemuch', 'north delhi', 'north goa', 'osmanabad', 'pali', 'palwal', 'panch mahal', 
      'panchkula', 'panipat', 'panna', 'parbhani', 'patan', 'pathankot', 'patiala', 'patna', 
      'pauri garhwal', 'pilibhit', 'pithoragarh', 'pratapgarh', 'pratapgarh(up)', 'pune', 'raebareli', 
      'raichur', 'raigad', 'raisen', 'rajgarh', 'rajkot', 'rajouri', 'rajsamand', 'ramanagara', 
      'rampur', 'ranchi', 'ratlam', 'ratnagiri', 'rewa', 'rewari', 'rohtak', 'rohtas', 'rupnagar', 
      's.a.s. nagar', 'sabarkantha', 'sagar', 'saharanpur', 'saharsa', 'samastipur', 'samba', 'sambhal', 
      'sangli', 'sangrur', 'sant kabir nagar', 'sant ravidass nagar', 'saran', 'satara', 'satna', 
      'sawai madhopur', 'sehore', 'seoni', 'shahdol', 'shahjahanpur', 'shajapur', 'shamli', 
      'sheikhpura', 'sheohar', 'sheopur', 'shivamogga', 'shivpuri', 'shravasti', 'siddharthnagar', 
      'sidhi', 'sikar', 'sindhudurg', 'singrauli', 'sirmaur', 'sirohi', 'sirsa', 'sitamarhi', 'sitapur', 
      'siwan', 'solan', 'solapur', 'sonbhadra', 'sonipat', 'south delhi', 'south goa', 'sri ganganagar', 
      'sultanpur', 'surat', 'surendranagar', 'tapi', 'tarn taran', 'thane', 'tikamgarh', 'tonk', 
      'tumakuru', 'udaipur', 'udham singh nagar', 'udupi', 'ujjain', 'umaria', 'una', 'unnao', 
      'uttar kannada', 'vadodara', 'vaishali', 'valsad', 'varanasi', 'vidisha', 'vijapura', 
      'vijayanagara', 'wayanad', 'west champaran', 'west delhi', 'yadgir', 'yamunanagar']
  };

  const productData = {
    Product: ["opc", "ppc", "ppc super protect", "ppc super strong", "psc"],
    Sub_Product: ["opc 43", "ppc", "ppc super protect", "ppc super strong", "psc", "opc 53", "opc 53 fast set", "opc 43 strong set", "opc 53 strong set", "opc 43 fast set"],
    Product_Category: ["premium","non premium"],
    Blended: [
      "blended","non blended"
  ]
  };

  const timePeriod = {
    FY: ["fy23", "fy24", "fy25"],
    Half_Year: ["h1","h2"],
    Quarter: ["q1","q2","q3","q4"],
    Year: ["2023","2024","2025"],
    Month: ["april", "may", "june", "july", "august", "september", "october", "november", "december", "january", "february", "march"]
  };

  const customerData = {
    Customer_Name: [
      "ABC"
  ],
    Customer_Group: [10, 11, 12, 14, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 30, 32, 33, 34, 38, 39, 44, 45],
    Current_Tier: ["bronze", "gold", "legend", "no tier", "platinum", "silver", "titanium"],
    Customer_Group_Name: [
      "15",
      "39",
      "45",
      "builders",
      "bulk consumer",
      "business organizer",
      "contractors",
      "ds&d",
      "government parties",
      "industrial customers",
      "institutional custom",
      "one time customers",
      "others",
      "project parties",
      "retailers",
      "sales promoter",
      "self consumption / s",
      "sez parties",
      "star / super stockis",
      "stockist",
      "trust / society"
  ],
    Plant: [
      1001, 1004, 1022, 1033, 1034, 1045, 1048, 1057, 1063, 1076, 1078, 1090, 1096, 1101, 1116, 1120, 
      1263, 1275, 1283, 1285, 1291, 1295, 1298, 1302, 1307, 1321, 1334, 1362, 1366, 1380, 1386, 1388, 
      1406, 1426, 1432, 1437, 1439, 1446, 1448, 1459, 1463, 1470, 1487, 1489, 1492, 1498, 1575, 1579, 
      1591, 1607, 1619, 1629, 1635, 1667, 1709, 1719, 1743, 2001, 2201, 2204, 2205, 2206, 2207, 2208, 
      2209, 2210, 2212, 2217, 2222, 2230, 2234, 2240, 2251, 2290, 2294, 2297, 2313, 2320, 2345, 2349, 
      2350, 2379, 2387, 2390, 2395, 2397, 2409, 2411, 2419, 2429, 6501, 6505, 6511, 6533, 8412, 8414, 
      8417, 8429, 8432, 8437
  ],
    Depot_Name: [
      "abohar_grey",
      "agra-1",
      "ahmednagar_grey",
      "aligarh (hub) agu",
      "aligarh hub",
      "alirajpur_grey",
      "alwar-1",
      "amausi (lucknow)_grey 3000",
      "aslali_grey",
      "aurangabad_grey",
      "bahraich_grey 3000",
      "badnawar hub_grey",
      "barmer_grey",
      "baroda_grey",
      "belgaum - 2",
      "belgaum_grey",
      "bellary_grey",
      "bhilwara_grey",
      "bhinmal hub",
      "bijapur_grey",
      "bicholim_grey",
      "bud0aun_alg",
      "burari_grey",
      "calicut_grey",
      "cherakala_grey",
      "chittore_grey",
      "dehgam_grey",
      "dungarpur_grey",
      "farukhabad_alg_grey",
      "farukhabad_grey",
      "gadag_grey",
      "gadhinglaj_grey",
      "guna_grey",
      "gokul shiragaon_grey",
      "haldwani_grey",
      "hamirpur_grey",
      "hasanpur_grey",
      "hasanpur(jp nagar)_alg_grey",
      "hassan_grey",
      "hisar-1",
      "hissar_grey",
      "hubli_grey",
      "indore-1",
      "j.k. cement works aligarh",
      "j.k. cement works balasinor",
      "j.k. cement works chickodi",
      "j.k. cement works muddapur",
      "j.k. cement works nimbahera",
      "j.k. cement works ramnagar",
      "j.k. cement works, mangrol",
      "jaykaycem (central) ltd.",
      "jaykaycem(central)ltd.hamirpur",
      "jabalpur_grey - 3000",
      "kankroli(rajsamand)",
      "kannur_grey",
      "katrajex_grey",
      "khargone_grey",
      "kurukshetra_grey",
      "loni_sow",
      "madgaon_grey",
      "mandsaur_grey",
      "mangalore_grey",
      "mani_grey",
      "mapusa_grey",
      "meerut_alg_grey",
      "meerut_grey",
      "moradabad_grey",
      "muzaffar nagar_grey",
      "mysore_grey",
      "nar0da_grey",
      "neem ka thana_grey",
      "neemuch hub 1",
      "nimbahera-hub",
      "noida_alg_grey",
      "olpad_grey",
      "panna depot - 3000",
      "pcm-g",
      "pcmc-2_grey",
      "ponda_grey",
      "pratapgarh_grey",
      "raichur_grey",
      "rani (pali district)",
      "ratlam_grey",
      "reengus(sikar)-grey",
      "rewa depot - 3000",
      "rudrapur",
      "s0irsa_grey",
      "sez parties",
      "shivpuri_grey",
      "shrim0garh",
      "shrirg",
      "sirsa_grey",
      "solmg",
      "solrpi",
      "surangp",
      "surangp parties",
    ],
    Valuation_Type: [1001, 1004, 1006, 1022, 1033, 1034, 1045, 1048, 1901, 1902, 1906, 1907, 
      2001, 8009, 8016, 8036, 8038, 8045, 8046, 8047, 8053, 8054, 8057, 8058, 
      8062, 8086, 8087, 8093, 8169, 8196],
    Plant_Name: ["Nimbahera", "Mangrol", "Jharli", "Aligarh", "Balasinor", "Panna", "Hamirpur", "Ujjain", "Prayagraj", "Muddapur"]
  };

  const otherAttributes = {
    Distribution_Channel: ["trade","non trade"],
    Dispatch_Type: ["direct","indirect"],
    Billing_Type: ["zdd2", "zdm2", "zdre", "zed2", "zfd2", "zfre"],
    Shipping_Conditions: ['53', '57', '58', '59', '61', '66', '67', '71', '76', '79', 
      'attached/integ. bulk', 'bulker primary sale', 'bulker trailer', 'bulker turbo', 
      'by rail (box-n)', 'by rail (normal)', 'cement xpress', 'cng_turbo', 
      'dealer own vehicle', 'dedicated trailer', 'dedicatedturbo/trail', 'e2e service', 
      'integrated', 'market (all load)', 'own exclusive fleet', 'own-attached south', 
      'own/attached south', 'pl vehicle', 'sec frt – rail yard', 'sec frt – rail yard', 
      'secondary general', 'sez trailer', 'small truckunderload', 'sow', 
      'tractor-truck-canter', 'tractor/truck/canter', 'trailer 18 w', 'trailer 22 w', 
      'train load - 2 p rk', 'train load - mini rk', 'turbo 10 w'],
    Shipping_Conditions_Desc: [4, 6, 9, 11, 12, 13, 16, 26, 27, 30, 33, 34, 37, 39, 40, 41, 42, 44, 47, 53, 57, 58, 59, 61, 66, 67, 71, 76, 79, 81],
    Price_List: ['"f', '"n', '"o', '%h', '%i', '%m', '%n', '%r', '%v', '(1', '(j', '(o', ')3', ')d', ')l', ')y', 
      '1/', '1;', '1\\', '2$', '2,', '2]', '2}', '3j', '3n', '3o', '3p', '4i', '4u', '6l', '7b', '7y', 
      '8l', '>8', '?5', '?9', '@8', '[a', '[b', 'b2', 'cc', 'cf', 'cn', 'ct', 'cx', 'ej', 'ek', 'el', 
      'em', 'en', 'ep', 'er', 'et', 'f5', 'fk', 'gb', 'gd', 'gi', 'hl', 'i1', 'i2', 'i3', 'ib', 'ic', 
      'if', 'in', 'ip', 'ki', 'l3', 'l6', 'le', 'lg', 'lh', 'lt', 'ly', 'mb', 'mc', 'mh', 'mn', 'mv', 
      'n4', 'nd', 'nh', 'ny', 'rb', 'rc', 'u2', 'ur', 'vb', 'vd', 've', 'vl', 'vn', 'vq', 'vv', 'vy', 
      'xo', 'zb', 'ze'],
    Material: [110000, 110001, 110002, 110003, 110004, 110005, 110006, 110007, 110009, 110010, 
      110011, 110012, 110013, 110016, 110019, 110020, 110021, 110022, 110023, 110024, 
      110025, 110027, 110028, 110029, 110030, 110031, 200004, 200005, 200016, 200091, 
      202398, 202399, 202400, 202404],
    Invoice_No: [" "]
  };

  const salesMetricsData = {
    Sales: ["ABC"],
    Variable_Cost: ["ABC"],
    Gross_Value: ["ABC"],
    Discount: ["ABC"],
    GST: ["ABC"],
      Charity_Taxable: ["ABC"],
  };

  const logisticsMetricsData = {
    Primary_Freight: ["ABC"],
    Secondary_Freight: ["ABC"],
    Handling_Charges: ["ABC"],
    Packaging: ["ABC"]
  };

  const RetentionMetricsData = {
    Final_Retention: ["ABC"],
  };

  useEffect(() => {
    const newThreadId = generateCustomThreadId();
    setThreadId(newThreadId);
    console.log('Generated Thread ID:', newThreadId);
  }, []);

  const startNewChat = () => {
    setMessages([]);
    setThreadId(generateCustomThreadId());
    console.log('Starting a new chat with a new thread ID:', threadId);
  };

  const handleSendMessage = async (text) => {
    const messageToSend = text || input;
    if (!messageToSend.trim()) return;

    const updatedMessages = [...messages, { text: messageToSend, type: 'user' }];
    setMessages(updatedMessages);
    setInput('');
    setTyping(true);

    const payload = {
      message: messageToSend,
      thread_id: threadId,
    };

    try {
      if (useApiData) {
        const response = await fetch(INSIGHT_GPT_API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        const data = await response.json();
        setTyping(false);

        const { final_output, reasoning, visualization_output, visualization_type } = data;
        const chartData = visualization_output?.formatted_data_for_visualization || null;

        const botResponse = {
          text: final_output,
          type: 'bot',
          reasoning1: reasoning,
          chartData: chartData
            ? {
                labels: chartData.xValues,
                data: chartData.yValues,
                xLabel: chartData.xLabel,
                yLabel: chartData.yLabel,
              }
            : null,
          chartType: visualization_type,
        };

        setMessages((prevMessages) => [...prevMessages, botResponse]);
      }
    } catch (error) {
      console.error('Error in sending message:', error);
      setTyping(false);

      const errorMessage = {
        text: `Error: Could not send the message. ${error.message}`,
        type: 'bot',
      };

      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleSpeechStart = () => {
    recognitionRef.current = initSpeechRecognition(language, handleSendMessage, setIsRecording);
    if (recognitionRef.current) recognitionRef.current.start();
  };

  const handleSpeechEnd = () => {
    stopSpeechRecognition(recognitionRef.current, mediaRecorderRef.current);
  };

  return (
    <div className="conversation-layout">
      <div className={`sidebar ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <h3>Guide</h3>
        <span className="close-sidebar" onClick={toggleSidebar}>&#10005;</span>

        <div className="sidebar-bottom-text">
          Data available from Dec 2022 to Oct 2024 [except March 2024]*
        </div>

        <div className="additional-info">
          <p>
            <strong>The Insight GPT tool</strong> helps analyze sales and retention data across various dimensions. Users can explore data by geography, product, time period, customer, and more to answer specific business questions. Here’s how you can use it effectively.
          </p>

          <h4>Sample Questions You Can Ask</h4>
          <h5>1. Sales Analysis:</h5>
          <ul>
            <li>What is the total sale in North cluster in FY25?</li>
            <li>What is the trade sale in Region Haryana in September 2024?</li>
          </ul>

          <h5>2. Retention Analysis:</h5>
          <ul>
            <li>Which are the bottom 5 states with the lowest retention?</li>
            <li>How does FY2025 YTD compare against FY24 YTD trade sales?</li>
          </ul>

          <h5>3. Comparison Analysis:</h5>
          <ul>
            <li>What are the YTD sales for County Surat in trade compared to the same time last year?</li>
          </ul>

          <h4>Data Structure</h4>
          <p>The tool uses data categorized into the following main areas:</p>

          <h4 onClick={() => toggleSection('DataStructure')}>
            + Geography
            <img src={expandedSections.DataStructure ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
          </h4>
          
          {expandedSections.DataStructure && (
            <div className="data-structure">
              {/* Dynamically Render Sub-sections */}
              {Object.keys(dataStructure).map((section) => (
                <div key={section}>
                  <h5 onClick={() => toggleSection(section)}>
                    {section}
                    <img src={expandedSections[section] ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
                  </h5>
                  {expandedSections[section] && (
                    <ul>
                      {dataStructure[section].map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}

<h4 onClick={() => toggleSection('ProductData')}>
            + Product Data
            <img src={expandedSections.ProductData ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
          </h4>
          
          {expandedSections.ProductData && (
            <div className="data-structure">
              {/* Dynamically Render Sub-sections */}
              {Object.keys(productData).map((section) => (
                <div key={section}>
                  <h5 onClick={() => toggleSection(section)}>
                    {section}
                    <img src={expandedSections[section] ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
                  </h5>
                  {expandedSections[section] && (
                    <ul>
                      {productData[section].map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}

<h4 onClick={() => toggleSection('TimePeriod')}>
            + Time Period
            <img src={expandedSections.TimePeriod ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
          </h4>
          
          {expandedSections.TimePeriod && (
            <div className="data-structure">
              {/* Dynamically Render Sub-sections */}
              {Object.keys(timePeriod).map((section) => (
                <div key={section}>
                  <h5 onClick={() => toggleSection(section)}>
                    {section}
                    <img src={expandedSections[section] ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
                  </h5>
                  {expandedSections[section] && (
                    <ul>
                      {timePeriod[section].map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}

<h4 onClick={() => toggleSection('CustomerData')}>
            + Customer Data
            <img src={expandedSections.CustomerData ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
          </h4>
          
          {expandedSections.CustomerData && (
            <div className="data-structure">
              {/* Dynamically Render Sub-sections */}
              {Object.keys(customerData).map((section) => (
                <div key={section}>
                  <h5 onClick={() => toggleSection(section)}>
                    {section}
                    <img src={expandedSections[section] ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
                  </h5>
                  {expandedSections[section] && (
                    <ul>
                      {customerData[section].map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}

<h4 onClick={() => toggleSection('OtherAttributes')}>
            + Other Attributes
            <img src={expandedSections.OtherAttributes ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
          </h4>
          
          {expandedSections.OtherAttributes && (
            <div className="data-structure">
              {/* Dynamically Render Sub-sections */}
              {Object.keys(otherAttributes).map((section) => (
                <div key={section}>
                  <h5 onClick={() => toggleSection(section)}>
                    {section}
                    <img src={expandedSections[section] ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
                  </h5>
                  {expandedSections[section] && (
                    <ul>
                      {otherAttributes[section].map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}

        <h4>Metrics</h4>
        <p>The tool provides various metrics to support your analysis:</p>

          <h4 onClick={() => toggleSection('SalesMetricsData')}>
            + Sales Metrics
            <img src={expandedSections.DataStructure ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
          </h4>
          
          {expandedSections.SalesMetricsData && (
            <div className="data-structure">
              {/* Dynamically Render Sub-sections */}
              {Object.keys(salesMetricsData).map((section) => (
                <div key={section}>
                  <h5 onClick={() => toggleSection(section)}>
                    {section}
                    <img src={expandedSections[section] ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
                  </h5>
                  {expandedSections[section] && (
                    <ul>
                      {salesMetricsData[section].map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}

<h4 onClick={() => toggleSection('LogisticsMetricsData')}>
            + Freight Metrics
            <img src={expandedSections.DataStructure ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
          </h4>
          
          {expandedSections.LogisticsMetricsData && (
            <div className="data-structure">
              {/* Dynamically Render Sub-sections */}
              {Object.keys(logisticsMetricsData).map((section) => (
                <div key={section}>
                  <h5 onClick={() => toggleSection(section)}>
                    {section}
                    <img src={expandedSections[section] ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
                  </h5>
                  {expandedSections[section] && (
                    <ul>
                      {logisticsMetricsData[section].map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}

<h4 onClick={() => toggleSection('RetentionMetricsData')}>
            + Retention Metrics
            <img src={expandedSections.DataStructure ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
          </h4>
          
          {expandedSections.RetentionMetricsData && (
            <div className="data-structure">
              {/* Dynamically Render Sub-sections */}
              {Object.keys(RetentionMetricsData).map((section) => (
                <div key={section}>
                  <h5 onClick={() => toggleSection(section)}>
                    {section}
                    <img src={expandedSections[section] ? arrowUpIcon : arrowDownIcon} alt="Toggle" className="toggle-icon" />
                  </h5>
                  {expandedSections[section] && (
                    <ul>
                      {RetentionMetricsData[section].map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}
          
        </div>
      </div>

      

      <div className="hamburger-menu" onClick={toggleSidebar}>
        &#9776;
      </div>

      <div className="main-conversation">
        <header className="top-header">
          <img src={logoLeft} alt="Sales Sherpa Left" className="logo-left" />
          <img src={logoRight} alt="JKC Right" className="logo-right" />
        </header>

        <ConversationBubbles messages={messages} typing={typing} />

        <InputField
          input={input}
          setInput={setInput}
          handleSendMessage={handleSendMessage}
          isRecording={isRecording}
          handleSpeechStart={handleSpeechStart}
          handleSpeechEnd={handleSpeechEnd}
        />
      </div>
    </div>
  );
};

export default InsightConversationScreen;