import React, { useEffect, useRef } from 'react';
import humanIcon from '../assets/icons/humanIcon.png'; // Path to user avatar
import botIcon from '../assets/icons/botIcon.png'; // Path to bot avatar

const GOOGLE_MAPS_API_KEY = 'AIzaSyAr4s0zAWoiVXVv1_wJ7H_LsaIf55flAac'; // Replace with your API key

const ConversationBubbles = ({ messages, typing }) => {
  const chatEndRef = useRef(null);

  // Scroll to the bottom whenever messages or typing state changes
  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom(); // Scroll whenever messages change or typing loader is shown
  }, [messages, typing]);

  // Function to generate Google Maps static map URL using latitude and longitude
  const generateMapUrl = (latitude, longitude) => {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=14&size=400x400&markers=color:blue%7Clabel:S%7C${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`;
  };

  // Function to extract coordinates and clean the text (fallback if mapUrl not provided)
  const extractCoordinates = (text) => {
    const latLngRegex = /Coordinates:\s*Latitude\s*(\d+\.\d+),\s*Longitude\s*(\d+\.\d+)/;
    const match = text.match(latLngRegex);

    if (match) {
      const latitude = match[1];
      const longitude = match[2];
      const cleanedText = text.replace(latLngRegex, ''); // Remove the coordinates and "Coordinates" word
      return { latitude, longitude, cleanedText };
    }

    return { latitude: null, longitude: null, cleanedText: text };
  };

  return (
    <div className="conversation-container">
      {messages.map((message, index) => {
        const isUserMessage = message.type === 'user';
        const isFirstBotMessage = !isUserMessage && (index === 0 || messages[index - 1].type === 'user');

        // Extract coordinates and cleaned text from the message (if coordinates are embedded in the text)
        const { latitude, longitude, cleanedText } = extractCoordinates(message.text || '');

        // If mapUrl is provided in the message, use it; otherwise, generate from coordinates
        const mapUrl = message.mapUrl || (latitude && longitude ? generateMapUrl(latitude, longitude) : null);

        return (
          <div
            key={index}
            className={`chat-bubble ${isUserMessage ? 'user-bubble' : 'bot-bubble'}`}
          >
            {/* User Avatar for User Messages */}
            {isUserMessage && (
              <img src={humanIcon} alt="User" className="chat-icon user-avatar" />
            )}

            {/* Bot Avatar for the first bubble only after a user message */}
            {!isUserMessage && isFirstBotMessage && (
              <img src={botIcon} alt="Bot" className="chat-icon bot-avatar" />
            )}

            <div className="chat-content">
              {/* Add line break before Address */}
              {cleanedText && (
                <p dangerouslySetInnerHTML={{ __html: cleanedText.replace(/- Address:/g, '<br/><strong>Address:</strong>') }}></p>
              )}

              {/* If mapUrl exists, show the map */}
              {mapUrl && (
                <div className="chat-bubble-map">
                  <a href={mapUrl} target="_blank" rel="noopener noreferrer">
                    <img src={mapUrl} alt="Map" />
                  </a>
                </div>
              )}

              {/* Render image if provided */}
              {message.image && (
                <div className="chat-bubble-image">
                  <img src={message.image} alt="Image" />
                </div>
              )}
            </div>
          </div>
        );
      })}

      {/* Typing indicator for bot */}
      {typing && (
        <div className="chat-bubble bot-bubble typing-indicator">
          <span></span><span></span><span></span>
        </div>
      )}

      {/* Dummy div to ensure the scroll moves to the bottom */}
      <div ref={chatEndRef} />
    </div>
  );
};

export default ConversationBubbles;