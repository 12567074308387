// InputField.js

import React from 'react';
import sendIcon from '../assets/icons/send-icon.svg';
import micIcon from '../assets/icons/mic-icon.svg';
import stopIcon from '../assets/icons/stop-icon.svg';

const InputField = ({ input, setInput, handleSendMessage, isRecording, handleSpeechStart, handleSpeechEnd }) => {
  return (
    <div className="message-input">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type a message..."
        className="input-field"
      />
      <button className="send-button" onClick={() => handleSendMessage()}>
        <img src={sendIcon} alt="Send" className="icon" />
      </button>
      <button
        className="speech-button"
        onClick={isRecording ? handleSpeechEnd : handleSpeechStart}
      >
        <img src={isRecording ? stopIcon : micIcon} alt="Speech" className="icon" />
      </button>
    </div>
  );
};

export default InputField;