import React, { useState, useEffect, useRef } from 'react';
import humanIcon from '../assets/icons/humanIcon.png';
import botIcon from '../assets/icons/botIcon.png';
import { Chart } from 'chart.js/auto';
import ReactMarkdown from 'react-markdown';
import arrowDownIcon from '../assets/icons/arrow-down.svg';
import arrowUpIcon from '../assets/icons/arrow-up.svg';
import BotChart from './BotChart';

// Component for rendering charts in conversation bubbles
const ChartBubble = ({ chartData, chartType }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) {
      console.error('Chart reference is not available.');
      return;
    }

    const ctx = chartRef.current.getContext('2d');
    let chartInstance;

    try {
      if (!chartData || !chartData.labels || !chartData.data) {
        console.error('Invalid chart data:', chartData);
        return;
      }

      const colors = chartData.labels.map(() => {
        return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
          Math.random() * 255
        )}, ${Math.floor(Math.random() * 255)}, 0.6)`;
      });

      let datasets;
      if (chartType === 'line') {
        datasets = chartData.data.map((dataset, index) => ({
          label: dataset.label || `Dataset ${index + 1}`,
          data: dataset.data,
          borderColor: colors[index],
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          spanGaps: true,
        }));
      } else if (chartType === 'bar') {
        datasets = [
          {
            label: chartData.label || 'Dataset',
            data: chartData.data,
            backgroundColor: colors,
            borderColor: colors.map((color) => color.replace('0.6', '1')),
            borderWidth: 1,
          },
        ];
      } else {
        console.error('Unsupported chart type:', chartType);
        return;
      }

      chartInstance = new Chart(ctx, {
        type: chartType,
        data: {
          labels: chartData.labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 0,
          },
          scales: {
            x: {
              title: {
                display: true,
                text: chartData.xLabel,
              },
            },
            y: {
              title: {
                display: true,
                text: chartData.yLabel,
              },
            },
          },
        },
      });
    } catch (error) {
      console.error('Error initializing chart:', error);
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [chartData, chartType]);

  return <canvas ref={chartRef} style={{ width: '100%', height: '300px' }}></canvas>;
};

// Function to parse reasoning content and handle SQL blocks
const parseReasoningContent = (content) => {
  const regex = /```sql([\s\S]*?)```/g;
  const parts = content.split(regex);

  return parts.map((part, index) => {
    if (index % 2 === 1) {
      return (
        <div key={index} className="sql-query-box">
          <pre>{part.trim()}</pre>
        </div>
      );
    }
    return <p key={index}>{part}</p>;
  });
};

// Component to handle expandable content for reasoning and tables/charts
const ExpandableContent = ({ title, content, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="expandable-section">
      <h4
        onClick={toggleExpand}
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span style={{ fontWeight: 'bold' }}>{title}</span>
        <img
          src={isExpanded ? arrowUpIcon : arrowDownIcon}
          alt={isExpanded ? 'Collapse' : 'Expand'}
          className="arrow-icon"
        />
      </h4>
      {isExpanded && (
        <>
          <div className="reasoning-content">
            {parseReasoningContent(content)}
          </div>
          {children && <div>{children}</div>}
        </>
      )}
    </div>
  );
};

// Main component to handle conversation bubbles, charts, and tables
const InsightConversationBubbles = ({ messages, typing }) => {
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, typing]);

  return (
    <>
      <div className="conversation-container">
        {messages.map((message, index) => {
          const isUserMessage = message.type === 'user';

          return (
            <div
              key={index}
              className={`chat-bubble ${
                isUserMessage
                  ? 'user-bubble'
                  : message.type === 'image'
                  ? 'bot-image-bubble'
                  : 'bot-bubble'
              }`}
            >
              {/* User Avatar for User Messages */}
              {isUserMessage && (
                <img src={humanIcon} alt="User" className="chat-icon user-avatar" />
              )}

              {/* Bot Avatar for Text and Image Messages */}
              {!isUserMessage && message.type !== 'image' && (
                <img src={botIcon} alt="Bot" className="chat-icon bot-avatar" />
              )}

              <div className="chat-content">
                {/* Display User Messages */}
                {isUserMessage && message.text && <p>{message.text}</p>}

                {/* Bot Final Output, Reasoning, and Visualizations */}
                {!isUserMessage && message.type === 'bot' && (
                  <>
                    {/* Display Final Output in Markdown format */}
                    {message.text && <ReactMarkdown>{message.text}</ReactMarkdown>}

                    {/* Graph Plot Visualization */}
                    {/* UNCOMMENT THIS TO ENABLE REASONING */}
                    {/* 
                    {message.chartData && (
                      <ExpandableContent
                        title="Graph Plot"
                        content="Here is the chart for further analysis."
                      >
                        <div className="chart-container">
                          <ChartBubble
                            chartData={message.chartData}
                            chartType={message.chartType}
                          />
                        </div>
                      </ExpandableContent>
                    )}
                    */}

                    {/* UNCOMMENT THIS TO ENABLE REASONING */}
                    {/* Reasoning with SQL */}
                    {/* 
                    {message.reasoning1 && (
                      <ExpandableContent title="Reasoning" content={message.reasoning1} />
                    )}
                    */}
                  </>
                )}
              </div>
            </div>
          );
        })}

        {/* Typing indicator for bot */}
        {typing && (
          <div className="chat-bubble bot-bubble typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}

        {/* Ensure the scroll moves to the bottom */}
        <div ref={chatEndRef} />
      </div>
    </>
  );
};

export default InsightConversationBubbles;